import { createTheme } from "@mui/material/styles"

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#5BB5A4",
      mid: "#C9F0E5",
      light: "#E9F9F5",
    },
    secondary: {
      main: "#6e4309",
    },
    white: {
      main: "#ffffff",
      mid: "#FAF4F1",
      mid2: "#ffece0",
      dark: "#F4E7E1",
      darker: "#D8AC97",
    },
    greys: {
      main: "#272727de",
    },
  },
  spacing: [
    6.2, 7.7, 9.7, 12.1, 15.2, 19, 23.7, 29.6, 37.1, 46.3, 57.9, 72.4, 90.5,
    113.2, 141.5,
  ],
})

let theme = createTheme({
  typography: {
    fontFamily: ["League Spartan", "sans-serif"].join(","),
    color: defaultTheme.palette.greys.main,
    h1: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: defaultTheme.spacing(11),
      lineHeight: defaultTheme.spacing(11),
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: defaultTheme.spacing(13),
        lineHeight: defaultTheme.spacing(13),
      },
      [defaultTheme.breakpoints.up("lg")]: {
        fontSize: defaultTheme.spacing(13),
        lineHeight: defaultTheme.spacing(13),
      },
      [defaultTheme.breakpoints.up("xl")]: {
        fontSize: defaultTheme.spacing(13),
        lineHeight: defaultTheme.spacing(13),
      },
    },
    h2: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: defaultTheme.spacing(9),
      lineHeight: defaultTheme.spacing(12),
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: defaultTheme.spacing(12),
      },
    },
    h3: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h4: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: defaultTheme.spacing(7),
      lineHeight: defaultTheme.spacing(9),
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: defaultTheme.spacing(10),
        lineHeight: defaultTheme.spacing(10),
      },
    },
    h5: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: defaultTheme.spacing(7),
      lineHeight: defaultTheme.spacing(7),
    },
    body1: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontSize: defaultTheme.spacing(5),
      lineHeight: defaultTheme.spacing(7),
      textWrap: "balance",
    },
    body2: {
      fontSize: defaultTheme.spacing(5),
      lineHeight: defaultTheme.spacing(7),
      fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
    subtitle1: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontWeight: 400,
      textTransform: "uppercase",
      letterSpacing: 1,
      fontSize: defaultTheme.spacing(3),
      lineHeight: defaultTheme.spacing(4),
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: defaultTheme.spacing(4),
      },
    },
    subtitle2: {
      fontFamily: ["League Spartan", "sans-serif"].join(","),
      fontWeight: 400,
      fontSize: defaultTheme.spacing(3),
      lineHeight: defaultTheme.spacing(4),
      letterSpacing: 1,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: defaultTheme.spacing(3),
      },
    },
  },
  palette: defaultTheme.palette,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.spacing(4),
          lineHeight: defaultTheme.spacing(4),
        },
        input: {
          paddingLeft: defaultTheme.spacing(4),
          paddingRight: defaultTheme.spacing(4),
          paddingTop: defaultTheme.spacing(4),
          paddingBottom: defaultTheme.spacing(4),
          height: "auto",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.spacing(4),
          lineHeight: defaultTheme.spacing(4),
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          fontFamily: "League Spartan, sans-serif",
          letterSpacing: 1,
          padding: "13px 16px 10px 16px",
        },
      },
      variants: [
        {
          props: { variant: "outlined", size: "large" },
          style: {
            textTransform: "none",
            border: `2px solid #ffffff`,
            color: `#ffffff`,
            padding: "1rem 1.4rem",
            "&:hover": {
              border: `2px solid #ffffff`,
              backgroundColor: "rgba(255,255,255,0.3)",
            },
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: defaultTheme.palette.primary.main,
            color: defaultTheme.palette.white.main,
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            border: `2px dashed ${defaultTheme.palette.secondary.main}`,
            color: defaultTheme.palette.secondary.main,
          },
        },
        {
          props: { variant: "dashed", size: "large" },
          style: {
            borderWidth: 4,
          },
        },
        {
          props: { variant: "dashed", color: "secondary", size: "large" },
          style: {
            fontSize: 18,
          },
        },
      ],
    },
  },
})

export default theme
